
import axios from "axios";
import { AuthType, IResponse } from "../interface/I_General";
const API_URL = process.env.REACT_APP_API_URL

export const listUser = async(auth: AuthType) => {
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/listUser`,
                            {},
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const ativarInativarUser = async(data: any, auth: AuthType) => {
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/ativarInativarUser`,
                            { data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

export async function registerUser(data: string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/registerUser`,
                            { data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}


export async function updateUser(data: any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/updateUser`,
                            { data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}


export async function getUser(id: any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getUser`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}